const ACTIVE = 'active';
const INACTIVE = 'inactive';
const PENDING = 'pending';
const DELETE = 'delete';

export default {
  ACTIVE,
  INACTIVE,
  PENDING,
  DELETE
};
